<template>
  <v-container
    grid-list-md
    fill-height
  >
    <v-layout
      justify-center
      row
      wrap
    >
      <v-flex
        xs12
        md8
      >
        <h2>Sign Up</h2>
        <v-divider class="my-3" />
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
            >
              Basic Information
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
            >
              Address
            </v-stepper-step>

            <v-divider />

            <v-stepper-step step="3">
              Contact Information
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-5"
                height="300px"
              >
                <v-form ref="form1">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex xs3>
                      <v-text-field
                        v-model="user.lastName"
                        class="required"
                        :rules="[
                          rules.required,
                          rules.length(0, 50)
                        ]"
                        :counter="50"
                        label="Family Name"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="user.middleName"
                        label="Middle Name"
                        :rules="[
                          rules.length(0, 50)
                        ]"
                        :counter="50"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="user.firstName"
                        class="required"
                        label="Given Name"
                        :rules="[
                          rules.required,
                          rules.length(0, 50)
                        ]"
                        :counter="50"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-text-field
                        v-model="user.preferName"
                        label="Preferred Name"
                        :rules="[
                          rules.length(0, 50)
                        ]"
                        :counter="50"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-menu
                        ref="dobMenu"
                        v-model="dobMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="user.dateOfBirth"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                      >
                        <v-text-field
                          slot="activator"
                          v-model="user.dateOfBirth"
                          class="required"
                          label="Date of Birth"
                          placeholder="yyyy-mm-dd"
                          prepend-icon="event"
                          :rules="[
                            rules.required,
                          ]"
                        />
                        <v-date-picker
                          v-model="user.dateOfBirth"
                          @input="$refs.dobMenu.save(user.dateOfBirth)"
                        />
                      </v-menu>
                    </v-flex>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="user.birthCountry"
                        class="required"
                        :items="countryOptions"
                        item-text="english_desc"
                        item-value="id"
                        label="Country of Birth"
                        :rules="[
                          rules.required,
                        ]"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="user.gender"
                        class="required"
                        :items="genderOptions"
                        item-text="text"
                        item-value="value"
                        label="Gender"
                        :rules="[
                          rules.required
                        ]"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="user.residentStatus"
                        class="required"
                        :items="residentStatusOptions"
                        item-text="text"
                        item-value="value"
                        label="Status in Canada"
                        :rules="[
                          rules.required
                        ]"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card>

              <v-btn
                color="primary"
                @click="fromTo(1, 2)"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-5"
                height="300px"
              >
                <v-card-text>
                  <v-form ref="form2">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.address"
                          class="required"
                          label="Current Address"
                          :counter="200"
                          :rules="[
                            rules.required,
                            rules.length(0,200)
                          ]"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="user.city"
                          class="required"
                          label="City"
                          :rules="[
                            rules.required,
                            rules.length(0,50)
                          ]"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="user.province"
                          class="required"
                          label="State/Province"
                          :rules="[
                            rules.required,
                            rules.length(0,50)
                          ]"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          v-model="user.postalCode"
                          class="required"
                          label="Postal/Zip Code"
                          :rules="[
                            rules.required,
                            rules.length(0,20)
                          ]"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <v-autocomplete
                          v-model="user.country"
                          class="required"
                          :items="countryOptions"
                          item-text="english_desc"
                          item-value="id"
                          label="Country"
                          :rules="[
                            rules.required,
                          ]"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
              </v-card>

              <v-btn
                color="primary"
                @click="fromTo(2, 3)"
              >
                Next
              </v-btn>

              <v-btn
                flat
                @click="e1 = 1"
              >
                Back
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-5"
                height="300px"
              >
                <v-card-text>
                  <v-form ref="form3">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex xs6>
                        <v-text-field
                          v-model="user.homephone"
                          class="required"
                          label="Home Phone"
                          :rules="[
                            rules.required,
                          ]"
                        />
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          v-model="user.cellphone"
                          class="required"
                          label="Mobile"
                          :rules="[
                            rules.required,
                          ]"
                        />
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.email"
                          class="required"
                          label="Email"
                          :rules="[
                            rules.required,
                            rules.email,
                          ]"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-btn
                color="primary"
                @click="submit"
              >
                Submit
              </v-btn>

              <v-btn
                flat
                @click="e1 = 2"
              >
                Back
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          Success
        </v-card-title>

        <v-card-text>
          Your login information will be sent to your email sortly.
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="resetForms"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import validate from '../custom/validate'

export default {
  data() {
    return {
      e1: 0,
      dialog: false,
      user: {},
      residentStatusOptions: [
        { text: 'Canadian Citizen', value: '1' },
        { text: 'Permanent Resident', value: '3' },
        { text: 'Visa Student', value: '4' },
        { text: 'Refugee Status', value: '7' },
        { text: 'Other Visa', value: '5' },
      ],
      genderOptions: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
      countryOptions: [],
      dobMenu: false,
      rules: null,
    }
  },
  created() {
    this.rules = validate.rules
    this.$store.dispatch('regions/allCountries')
      .then((data) => { this.countryOptions = data })
  },
  methods: {
    // navigation from form to form
    fromTo(from, to) {
      if (this.$refs[`form${from}`].validate()) {
        this.e1 = to
      }
    },
    submit() {
      // we validate last form, other forms are pre-checked
      if (!this.$refs.form3.validate()) { return }
      this.$store.dispatch('members/create', this.user)
        .then(() => {
          this.dialog = true
        })
    },
    resetForms() {
      // reset components after success
      this.$refs.form1.reset()
      this.$refs.form2.reset()
      this.$refs.form3.reset()
      this.e1 = 1
      this.dialog = false
    },
  },
}
</script>

<style>
</style>
